import { startCase } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'
import type { ContactPage as ContactPageType } from '~/types'
import { getPageSlug, resolve, useHubspotForm } from '~/utils'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../Accordion'
import { FaqGroup } from '../FaqGroup'
import { Body, Heading } from '../FontStyles'
import { HubspotContactForm } from '../HubspotContactForm'
import { PortableText } from '../blocks'

export interface ContactPageProps {
	page: ContactPageType
}

const StyledForm = styled(HubspotContactForm)`
	// Hide category radio buttons as they are displayed in subcategory form
	form > fieldset:first-child > div > div.hs-fieldtype-radio:first-child {
		display: none;
	}
`

type Step = 'topic' | 'subcategory' | 'form'

export const ContactPage: FunctionComponent<ContactPageProps> = ({ page }) => {
	const router = useRouter()

	const topic = page.topics?.find((t) => t.title === router.query.topic)
	const { form } = useHubspotForm(topic?.form?.formId)
	const subCategoryField = form?.formFieldGroups // Get the subcategory field from Hubspot Form
		.find((field) => field.fields[0]?.type === 'enumeration' && field.fields[0]?.fieldType === 'radio')
	const subCategory = subCategoryField?.fields[0]?.options.find((opt) => opt.value === router.query.subcategory)

	useEffect(() => {
		if (!subCategory || !topic?.form) return
		const interval = setInterval(() => {
			const selector = `#hsForm_wrapper_${topic.form.formId} input[value="${subCategory.value}"]`
			const elem: HTMLInputElement | null = document.querySelector(selector)
			if (elem) {
				triggerUpdate(elem)
			}
		}, 100)
		return () => clearInterval(interval)
	}, [topic, subCategory])

	const step: Step = subCategory ? 'form' : topic ? 'subcategory' : 'topic'

	return (
		<div className="space-y-12">
			<PortableText className="text-center" blocks={page.content} />
			<div className="grid items-start gap-10 md:grid-cols-2 lg:gap-32">
				<Accordion
					type="single"
					value={step}
					onValueChange={(value: Step) => {
						const url = new URL(location.href)
						if (value === 'topic') url.searchParams.delete('topic')
						if (value === 'topic' || value === 'subcategory') url.searchParams.delete('subcategory')
						router.replace(url, undefined, { shallow: true })
					}}
				>
					<AccordionItem value="topic">
						<AccordionTrigger
							trigger={
								step !== 'topic' && (
									<span className="self-start text-sm text-gray-600 underline">Edit</span>
								)
							}
						>
							{step === 'topic' ? 'Please pick a topic below.' : topic?.title}
						</AccordionTrigger>
						<AccordionContent>
							<div className="flex flex-col space-y-4">
								{page.topics?.map((t) => {
									return (
										<Link
											key={t._key}
											href={{
												query: { category: getPageSlug(page).category, topic: t.title }
											}}
											shallow
											prefetch={false}
										>
											<a className="space-y-2 rounded-2xl bg-stone-200 px-8 py-6 text-left">
												<Heading type="headingSm" className="font-bold">
													{t.title}
												</Heading>
												<Body className="opacity-80">{t.description}</Body>
											</a>
										</Link>
									)
								})}
							</div>
						</AccordionContent>
					</AccordionItem>
					{subCategoryField && (
						<AccordionItem value="subcategory" className={step === 'topic' ? '!border-b-0' : ''}>
							{step !== 'topic' && (
								<AccordionTrigger
									trigger={
										step !== 'subcategory' && (
											<span className="self-start text-sm text-gray-600 underline">Edit</span>
										)
									}
								>
									{!subCategory || step === 'subcategory'
										? 'Please pick a subcategory.'
										: subCategory.label || startCase(subCategory.value)}
								</AccordionTrigger>
							)}
							<AccordionContent>
								<div className="flex flex-col space-y-4">
									{subCategoryField.fields[0]?.options?.map((option, i) => (
										<Link
											key={i}
											href={{
												query: {
													category: getPageSlug(page).category,
													topic: topic?.title,
													subcategory: option.value
												}
											}}
											shallow
											prefetch={false}
										>
											<a className="space-y-2 rounded-2xl bg-stone-200 px-8 py-6 text-left">
												<Body className="opacity-80">{option.label || option.value}</Body>
											</a>
										</Link>
									))}
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
					<AccordionItem value="form" className={step !== 'form' ? '!border-b-0' : ''}>
						{topic && (
							<AccordionContent forceMount compat>
								<StyledForm
									className={(subCategoryField ? subCategory?.value : true) ? '' : 'hidden'}
									{...topic.form}
								/>
							</AccordionContent>
						)}
					</AccordionItem>
				</Accordion>
				<FaqGroup faq={resolve(topic?.faq) ?? resolve(page.faq)} theme="primary" />
			</div>
			<Script key="hs-form-sdk" type="text/javascript" src="//js-eu1.hsforms.net/forms/embed/v2.js" />
		</div>
	)
}

/**
 * Trigger Hubspots form update event
 * @param elem
 */
function triggerUpdate(elem: HTMLInputElement) {
	if (elem.type === 'checkbox' || elem.type === 'radio') {
		elem.click()
	} else {
		elem.dispatchEvent(new Event('input', { bubbles: true }))
	}
}
