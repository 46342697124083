import { startCase } from 'lodash'
import { FunctionComponent, useMemo, useState } from 'react'
import styled from 'styled-components'
import { isPresent } from 'ts-extras'
import type { KbArticle, LegalPage, Menu } from '~/types'
import {
	categoryTitle,
	cn,
	getBorderColor,
	getLinkPath,
	getPageMeta,
	resolve,
	toPlainText,
	useIsomorphicEffect,
	useSettings
} from '~/utils'
import { Heading } from '../FontStyles'
import { SanityLink, SanityLinkProps } from '../SanityLink'
import { PortableText, StyledChevron } from '../blocks'

export interface ArticleProps {
	page: KbArticle | LegalPage
}

const StyledArticle = styled.article`
	--font-sizes-2: 12px;
	--font-sizes-3: 13px;
	--font-sizes-4: 15px;
	--font-sizes-5: 17px;
	--font-sizes-6: 20px;
	--font-sizes-7: 27px;
	--font-sizes-8: 36px;
	--font-sizes-9: 47px;
	--font-sizes-10: 63px;
	--font-sizes-11: 84px;

	> *:not(:last-child) {
		margin-bottom: 0.75em;
	}

	p:not(:last-child) {
		margin-bottom: 1.5em;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&:not(:first-child) {
			margin-top: 2em;
		}
	}

	> ul,
	> ol {
		list-style: unset;
		padding-left: 2.5em;

		> li {
			margin-bottom: 0.5em;
		}
	}

	> [data-layout='responsive'] > span:first-child {
		max-height: 30rem;
	}

	table {
		width: 100%;
		padding: 1rem;

		th {
			border-bottom: 2px solid ${getBorderColor};
		}
	}

	> ol {
		list-style: decimal;
		> li > ol {
			padding-left: 1.5em;
			list-style: lower-latin;
		}
	}
`

const MenuLink: FunctionComponent<SanityLinkProps> = (props) => (
	<SanityLink className="md:inactive:hover:underline inline-block w-full md:inline" useActive {...props} />
)

export const ArticlePage: FunctionComponent<ArticleProps> = ({ page }) => {
	const settings = useSettings()
	const menus = page._type === 'kbArticle' ? settings.kbSettings.menus : settings.legalSettings.menus
	const duration = useMemo(() => {
		if (!page.content) return 0
		const textLength = toPlainText(page.content).length
		return Math.ceil(textLength / 5 / 180) // 5 chars per word, 180 words per minute
	}, [page.content])

	const date = page.meta?.updatedAtOverride || page._updatedAt || page._createdAt
	const [lastUpdated, setLastUpdated] = useState<string>(new Date(date).toISOString())
	useIsomorphicEffect(() => {
		setLastUpdated(new Date(date).toLocaleString())
	}, [date])
	StyledChevron
	const [menuOpen, setMenuOpen] = useState(false)

	const title = getPageMeta(page).title
	const category = resolve(page.category)
	const isMainCategoryPage = categoryTitle(category) === title

	return (
		<div className="grid grid-rows-[auto,auto] items-start gap-4 md:grid-cols-[max(22rem),auto] md:grid-rows-1 md:py-8 xl:grid-cols-[max(22rem),auto,15rem]">
			<div className="rounded-xl bg-stone-200 md:sticky md:top-28 md:bg-transparent">
				<Heading
					type="headingSm"
					className="flex w-full cursor-pointer justify-between px-5 py-4 md:hidden"
					onClick={() => setMenuOpen(!menuOpen)}
				>
					<span>{categoryTitle(category)}</span>
					<StyledChevron className={cn('!duration-75', { 'rotate-[135deg]': menuOpen })} />
				</Heading>
				<div className="md:space-y-4">
					{menus
						?.map(resolve)
						.filter(isPresent)
						.map((menu, idx) => (
							<nav
								key={idx}
								className={cn(
									'space-y-3 border-t border-t-gray-400 p-4 text-sm md:block md:border-t-0 md:p-0',
									{
										hidden:
											!menuOpen ||
											(menu._type === 'menu'
												? resolve(resolve(menu.link?.pageReference)?.category)?._id ===
												  category?._id
												: menu._id === category?._id)
									}
								)}
							>
								<Heading type="headingSm">
									{menu._type === 'menu' ? (
										menu.link && getLinkPath(menu.link) ? (
											<MenuLink link={menu.link}>{menu.title}</MenuLink>
										) : (
											menu.title
										)
									) : (
										<MenuLink link={{ _type: 'link', staticPath: menu.slug.current }}>
											{menu.title || startCase(menu.slug?.current)}
										</MenuLink>
									)}
								</Heading>
								<ul className="hidden space-y-2 md:block">
									{(menu as Menu).items
										?.filter((item) => {
											const pageRef = resolve(item.pageReference)
											if (item._type === 'link') return true
											return pageRef?.slug?.current !== '/' && pageRef?.category
										})
										.map((item, i) => (
											<li key={item._key || i}>
												<MenuLink link={item} />
											</li>
										))}
								</ul>
							</nav>
						))}
				</div>
			</div>
			{page.content && (
				<StyledArticle className="md:col-start-2 md:row-start-1 md:max-w-3xl">
					<Heading type="displaySm" className={cn({ 'hidden md:block': isMainCategoryPage })}>
						{title}
					</Heading>
					<hr className={cn('-mt-6', { 'hidden md:block': isMainCategoryPage })} />
					{page._type === 'legalPage' && (
						<p className="!mb-8 text-sm opacity-70">Last updated: {lastUpdated}</p>
					)}
					{page._type === 'kbArticle' && duration > 1 && (
						<p className="!mb-8 -mt-1 text-sm opacity-70">Reading time: {duration} minutes</p>
					)}
					<PortableText blocks={page.content} wrapper={false} />
				</StyledArticle>
			)}
		</div>
	)
}
