import { screenSizes } from '@nomonosound/gravity'
import { useQuery } from '@tanstack/react-query'
import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import { pageQuery } from '~/queries'
import type { Country, Page, PageProps, Params } from '~/types'
import { filterDataToSingleItem, getClient, getInputBorder, usePreviewSubscription, useSettings } from '~/utils'
import NotFound from '../404'
import { ContentBlocks } from '../blocks/ContentBlocks'
import { DefaultLayout } from '../layout/DefaultLayout'
import { HeadWithMeta } from '../layout/Header'
import { ArticlePage } from './ArticlePage'
import { ContactPage } from './ContactPage'

export interface DefaultPageProps extends PageProps<Params> {
	/** Groq query to filter page by. Use in {@link pageQuery} */
	pageFilter: string
	page: Page[]
	country?: Country | null
}

const FrontPageLayout = styled(DefaultLayout)`
	.style--signup .hs-form {
		.hs_email label {
			display: none;
		}
		input[type='email'] {
			border: 1px solid ${getInputBorder};
		}
		display: flex;
		flex-flow: column nowrap;
		.legal-consent-container {
			order: 3;
		}
		.hs_submit {
			margin-top: 0.5rem;
		}
		@media (min-width: ${screenSizes.medium}) {
			display: grid;
			gap: 0 1rem;
			grid-template-rows: auto auto;
			grid-auto-flow: row;
			align-items: flex-end;
			grid-template-columns: minmax(auto, 20rem) min-content;

			.hs_email {
				grid-row-start: 1;
			}
			.hs_submit {
				grid-row-start: 1;
				grid-column-start: 2;
			}
			.legal-consent-container {
				grid-row-start: 2;
				grid-column-start: span 2;
			}
			.hs_error_rollup {
				grid-row-start: 3;
				grid-column-start: span 2;
			}
		}
	}
`

export const DefaultPage: FunctionComponent<DefaultPageProps> = ({
	pageFilter,
	preview,
	previewData,
	params,
	...props
}) => {
	const settings = useSettings()
	const { data, error } = usePreviewSubscription(pageQuery(pageFilter), {
		params,
		initialData: props.page,
		enabled: preview,
		dataset: previewData?.dataset
	})
	if (error) throw error
	const staticPage = filterDataToSingleItem(data?.length ? data : props.page, preview)

	const fallback = useQuery(
		params ? Object.values(params) : ['page'],
		() => getClient(preview, previewData).fetch(pageQuery(pageFilter), params ?? {}),
		{ enabled: !staticPage }
	)

	const page = staticPage ?? filterDataToSingleItem<Page>(fallback.data, preview)

	switch (page?._type) {
		case 'frontPage':
			return (
				<FrontPageLayout
					preview={previewData ?? preview}
					theme={{ current: page.theme, settings: null }}
					page={page}
					noMargin={false}
				>
					<HeadWithMeta page={page} />
					{page.contentBlocks && <ContentBlocks contentBlocks={page.contentBlocks} />}
				</FrontPageLayout>
			)

		case 'contactPage':
			return (
				<DefaultLayout preview={previewData ?? preview} page={page}>
					<HeadWithMeta page={page} />
					<ContactPage page={page} />
				</DefaultLayout>
			)

		case 'legalPage':
		case 'kbArticle':
			return (
				<FrontPageLayout
					preview={previewData ?? preview}
					theme={{
						current:
							settings.kbSettings.theme === 'inherit'
								? settings.siteSettings.theme
								: settings.kbSettings.theme,
						settings: null
					}}
					page={page}
				>
					<HeadWithMeta page={page} />
					<ArticlePage page={page} />
				</FrontPageLayout>
			)

		default:
			return fallback.isLoading ? null : <NotFound />
	}
}
